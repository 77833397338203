/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'
import { BLACKBOX_BLACKBOX_ALL_HEARTBIT_URL, BLACKBOXES_LIST_URL, BLACKBOXES_LIST_PAGEABLE_URL } from '../../../../urls'

type Props = {
  className: string
}

const DashbordBlackboxTable: React.FC<Props> = ({ className }) => {


  const { currentUser } = useAuth()
  const token = currentUser?.token
  const userRole = currentUser?.role
  const [blackboxList, setBlackboxList] = useState<any[]>([])
  const [uptimeList, setUptimeList] = useState<any[]>([]);
  const id = currentUser?.id

  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState()

  const createHeader = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', "" + token?.toString());
    return headers
  }
  async function searchDistributorId(listKarakutu: any) {
    const response = await fetch(BLACKBOX_BLACKBOX_ALL_HEARTBIT_URL, {
      method: "POST",
      headers: createHeader(),
      body: JSON.stringify(listKarakutu)
    })

      .then(
        res => res.json()
      )
      .then(
        res => {

          setUptimeList(res)
          // handleClose()

        }
      )
  }
  const getBlackboxByRole = () => {
    if (userRole === "1") {
      return JSON.stringify({
        "userRole": currentUser?.role,
        "companyId": currentUser?.id
      })
    }
    else if (userRole === "2") {
      return JSON.stringify({
        "userRole": currentUser?.role,
        "distributorId": currentUser?.id
      })
    }
    else if (userRole === "3") {
      return JSON.stringify({
        "userRole": currentUser?.role,
        "companyId": currentUser?.id
      })
    }
  }

  async function getBlackbox(role: string | undefined, id: number | undefined, appointed: boolean, page: number, size: number) {

    const response = await fetch(BLACKBOXES_LIST_PAGEABLE_URL + `?role=${role}&id=${id}&appointed=${appointed}&size=${size}&page=${page}`, {
      method: "GET",
      headers: createHeader(),

    })
      .then(
        res => res.json()
      ).then(
        res => {

          setBlackboxList(res["content"]);
          searchDistributorId(res["content"])
          setPageNumber(res["pageable"]["pageNumber"])
          setPageSize(res["pageable"]["pageSize"])
          setTotalElements(res["totalElements"])
          setTotalPages(res["totalPages"])
        }
      )
    // waits until the request completes...

  }


  useEffect(() => {

    getBlackbox(userRole, id, false, pageNumber, pageSize)

  }, [pageNumber]);
  const getStatusBlackbox = (status: string) => {
    if (status == "0") return 'badge badge-light-danger' //çalışmıyor 
    else if (status == "1") return 'badge badge-light-success' //çalışıyor
    else if (status == "2") return 'badge badge-light-warning' //bilinmiyor
  }
  const getStatusBlackboxString = (status: string) => {
    if (status == "0") return 'Pasif' //çalışmıyor 
    else if (status == "1") return 'Aktif' //çalışıyor
    else if (status == "2") return 'Bilinmiyor' //bilinmiyor
  }


  const pageReduce = () => {

    if (pageNumber >= 1 && pageNumber <= totalPages) {

      setPageNumber(pageNumber - 1)


    }
  }
  const pageIncrease = () => {

    if (pageNumber >= 0 && pageNumber < totalPages) {
      setPageNumber(pageNumber + 1)

    }
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Karakutu Listesi</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Karakutu sayısı: {Array.isArray(blackboxList) && blackboxList.length}</span>
        </h3>
        {/* <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_1'
              >
                Month
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_2'
              >
                Week
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_3'
              >
                Day
              </a>
            </li>
          </ul>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {
                    (Array.isArray(blackboxList) && Array.isArray(uptimeList) && uptimeList.length != 0) &&
                    uptimeList.map((currentBlackbox: any) => {


                      return (
                        <tr key={currentBlackbox["blackboxId"]}>
                          <td >
                            <div className='symbol symbol-45px me-2'>
                              <span className='symbol-label'>
                                <img
                                  src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
                                  className='h-50 align-self-center'
                                  alt=''
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {currentBlackbox["blackboxId"]}
                            </a>

                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {currentBlackbox["upTime"].replace("T", " ")}
                            </a>
                            <span className='text-muted fw-semibold d-block'>Çalıştırma zamanı</span>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {currentBlackbox["lastLog"] != null ? currentBlackbox["lastLog"].replace("T", " ") : "-"}
                            </a>
                            <span className='text-muted fw-semibold d-block'>Son veri tarihi</span>
                          </td>
                          <td className='text-end'>
                            <span className={getStatusBlackbox(currentBlackbox["status"])}>
                              {getStatusBlackboxString(currentBlackbox["status"])}</span>
                          </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            >
                              <KTIcon iconName='arrow-right' className='fs-2' />
                            </a>
                          </td>
                        </tr>
                      )
                    })
                  }


                </tbody>

                {/* end::Table body */}
              </table>

            </div>

            {/* end::Table */}
          </div>
          <div className='d-flex flex-stack flex-wrap pt-10'>
            <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of {totalElements} entries</div>

            <ul className='pagination'>
              <li className='page-item previous'>
                <a className='page-link' onClick={pageReduce}>
                  <i className='previous'></i>
                </a>
              </li>

              <li className='page-item active'>
                <a href='#' className='page-link'>
                  {pageNumber + 1}
                </a>
              </li>

              <li className='page-item next'>
                <a className='page-link' onClick={pageIncrease}>
                  <i className='next'></i>
                </a>
              </li>
            </ul>
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Brad Simmons
                      </a>
                      <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>React, HTML</td>
                    <td className='text-end'>
                      <span className='badge badge-light-success'>Approved</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Popular Authors
                      </a>
                      <span className='text-muted fw-semibold d-block'>Most Successful</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>Python, MySQL</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>In Progress</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Active Customers
                      </a>
                      <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>AngularJS, C#</td>
                    <td className='text-end'>
                      <span className='badge badge-light-danger'>Rejected</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/kickstarter.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Bestseller Theme
                      </a>
                      <span className='text-muted fw-semibold d-block'>Best Customers</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>ReactJS, Ruby</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>In Progress</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Active Customers
                      </a>
                      <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>AngularJS, C#</td>
                    <td className='text-end'>
                      <span className='badge badge-light-danger'>Rejected</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/vimeo.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        New Users
                      </a>
                      <span className='text-muted fw-semibold d-block'>Awesome Users</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>Laravel,Metronic</td>
                    <td className='text-end'>
                      <span className='badge badge-light-primary'>Success</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Popular Authors
                      </a>
                      <span className='text-muted fw-semibold d-block'>Most Successful</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>Python, MySQL</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>In Progress</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export { DashbordBlackboxTable }
