/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useAuth } from '../auth'
import { blackboxIdContext } from '../profile/components/contexts/BlackboxIdContext'
import { BLACKBOXES_DELETE_BY_ELFATEK_URL, BLACKBOXES_LIST_PAGEABLE_URL, GET_PAGEABLE_PRODUCT_BY_USERID } from '../../../urls'
import { AsideMenuItem } from '../../../_elfaiotbbx/layout/components/aside/AsideMenuItem'
import { toAbsoluteUrl } from '../../../_elfaiotbbx/helpers'






const ListProduct: React.FC = () => {
    const [blackboxList, setBlackboxList] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const { currentUser, logout } = useAuth()
    const [variant, setVariant] = useState("primary")
    const [showAlert, setShowAlert] = useState(false)
    const [alertText, setAlertText] = useState("")
    const token = currentUser?.token
    const userRole = currentUser?.role
    const id = currentUser?.id
    const blackboxContext = useContext(blackboxIdContext)

    const [pageNumber, setPageNumber] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState()
    const [refreshList, setRefreshList] = useState(false)
    const [numberOfProduct, setNumberOfProdcut] = useState(0)
    const [productList, setProductList] = useState<any[]>([])
    const createHeader = () => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', "" + token?.toString());
        return headers
    }


    useEffect(() => {

        getProduct()


    }, [loading, pageNumber, refreshList]);



    async function getProduct() {
        console.log(GET_PAGEABLE_PRODUCT_BY_USERID + `/?page=${pageNumber}&size=${pageSize}&userId=${currentUser?.id}`)
        const response = await fetch(GET_PAGEABLE_PRODUCT_BY_USERID + `/?page=${pageNumber}&size=${pageSize}&userId=${currentUser?.id}`, {
            method: "GET",
            headers: createHeader(),
        })
        if (response.status === 401) {
            logout();
            return;
        }
        const responseData = await response.json()
        setProductList(responseData["content"])
        setPageNumber(responseData["pageable"]["pageNumber"])
        setPageSize(responseData["pageable"]["pageSize"])
        setTotalElements(responseData["totalElements"])
        setTotalPages(responseData["totalPages"])
        setNumberOfProdcut(responseData["content"].length)
        console.log(responseData)
    }


    const pageReduce = () => {

        if (pageNumber >= 1 && pageNumber <= totalPages) {

            setPageNumber(pageNumber - 1)


        }
    }
    const pageIncrease = () => {

        if (pageNumber >= 0 && pageNumber < totalPages) {
            setPageNumber(pageNumber + 1)

        }
    }

    function getProductGroup(deviceType: string) {
        if (deviceType === "0") return "Bilinmiyor"
        else if (deviceType === "1") return "Rulman"
        else if (deviceType === "2") return "Role"
        else if (deviceType === "3") return "Kasnak"
        else if (deviceType === "4") return "Kontaktör"
        else if (deviceType === "5") return "Halat"
        else if (deviceType === "6") return "Button"
        else if (deviceType === "7") return "Kanca"
        else if (deviceType === "8") return "kasnak"
        else if (deviceType === "9") return "Motor"
        else if (deviceType === "10") return "Diğer"
        else return "Bilinmiyor"
    }
    return (


        <div>

            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Ürünler</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'></span>
                </h3>
                <div className='card-toolbar'>
                    {/* begin::Menu */}

                    {/* <AsideMenuItem to='/crafted/pages/wizards/AddBlackbox' title='Ekle' hasBullet={true} /> */}
                    {/* begin::Menu 2 */}


                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted bg-light'>
                                <th className='min-w-125px'>Ürün id</th>
                                <th className='ps-4 min-w-300px rounded-start'>Marka</th>
                                <th className='min-w-125px'>Oluşturma Tarihi</th>
                                <th className='min-w-125px'>Açıklama</th>
                                <th className='min-w-200px'>Kullanım Ömrü</th>
                                <th className='min-w-150px'>Cihaz Grup</th>
                                <th className='min-w-200px text-end rounded-end'></th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {
                                (productList.length != 0) &&
                                productList.map((product: any) => {

                                    return (
                                        <tr key={product["id"]}>
                                            <td >
                                                
                                                <div className='d-flex align-items-center'>
                                                   
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                            {product.id}
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                            </td>
                                            <td >
                                                
                                                <div className='d-flex align-items-center'>
                                                   
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                            {product.brand}
                                                        </a>
                                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                            {product.model}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {product.createdTime.replace("T", " ")}
                                                </a>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>{product.city}</span>
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {product.description}
                                                </a>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'></span>
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {product.maxUsageTime}
                                                </a>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>

                                                </span>
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {getProductGroup(product["deviceType"])}
                                                </a>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>

                                                </span>
                                            </td>
                                            <td className='text-end'>


                                                {/* <a

                                                    className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2 '
                                                //onClick={e => deleteBlackbox(blackbox.serialNumber)}
                                                >
                                                    Sil
                                                </a> */}
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
            <div className='d-flex flex-stack flex-wrap pt-10'>
                <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of {totalElements} entries</div>

                <ul className='pagination'>
                    <li className='page-item previous'>
                        <a className='page-link' onClick={pageReduce}>
                            <i className='previous'></i>
                        </a>
                    </li>

                    <li className='page-item active'>
                        <a href='#' className='page-link'>
                            {pageNumber + 1}
                        </a>
                    </li>

                    <li className='page-item next'>
                        <a className='page-link' onClick={pageIncrease}>
                            <i className='next'></i>
                        </a>
                    </li>
                </ul>
            </div>
            <Alert key={variant} variant={variant} show={showAlert}>
                {alertText}
            </Alert>
        </div>

    )
}

export { ListProduct }
