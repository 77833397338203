/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTIcon} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import { BLACKBOXES_MAX_TEMP_URL,BLACKBOXES_AVG_TEMP_URL } from '../../../../urls'
import { blackboxIdContext } from '../../../../app/modules/profile/components/contexts/BlackboxIdContext'
import { useAuth } from '../../../../app/modules/auth'
type Props = {
  className: string
}

const BlackboxTempGraph: React.FC<Props> = ({className}) => {
  const blackboxContext = useContext(blackboxIdContext)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [tempList, setTempList] = useState([])
  const [maxTempList, setmaxTempList] = useState([])
  const [loading, setLoading] = useState(false)
  const {mode} = useThemeMode()
  const { currentUser } = useAuth()
  const token = currentUser?.token

  const createHeader = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', "" + token?.toString());
    return headers
  }

  useEffect(() => {
    getTemp5Days(blackboxContext,createHeader())
    getMaxTemp5Days(blackboxContext,createHeader())
    setTimeout(function () { setLoading(true) },3000)
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode,loading])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height,tempList,maxTempList))
    if (chart) {
      chart.render()
    }

    return chart
  }
  async function getTemp5Days(data:any, headers:Headers) {
    const response = await fetch(BLACKBOXES_AVG_TEMP_URL+`?blackboxId=${blackboxContext[0]}`,{
      method:"GET",
      headers: headers,
     
    })
      .then(
        res => res.json()
      ).then(
        res => {
          setTempList(res)
          
        }
      )
    // waits until the request completes...

  }
  async function getMaxTemp5Days(data:any,headers:Headers) {
    const response = await fetch(BLACKBOXES_MAX_TEMP_URL+`?blackboxId=${blackboxContext[0]}`,{
      method:"GET",
      headers: headers
     
    })
      .then(
        res => res.json()
      ).then(
        res => {
          setmaxTempList(res)
          
        }
      )
    // waits until the request completes...

  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Cihaz Sıcaklığı</span>

          <span className='text-muted fw-semibold fs-7'>Son 5 gün</span>
        </h3>
        {/* end::Title */}

        {/* begin::Toolbar */}
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {BlackboxTempGraph}

function getChartOptions(height: number,data:any,maxTempList:any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    series: [
      {
        name: 'Ortalama Sıcaklık',
        data: data["temp"],
      },
      {
        name: 'Max Sıcaklık',
        data: maxTempList["temp"],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data["date"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '10px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return  val + ' °C'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
