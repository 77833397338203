/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTIcon} from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'
import { BLACKBOXES_RS485_LIST_SIZE_URL } from '../../../../urls'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
}

const Rs458NumberWidget: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
}) => {
  
  const {currentUser} = useAuth()
  const token = currentUser?.token
  const [blackboxNumber, setBlackboxNumber] = useState(0)

  const createHeader = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', "" + token?.toString());
    return headers
}

  useEffect(() => {
    getBlackboxNumber()
  },[]) 

  async function getBlackboxNumber() {
    const response = await fetch(BLACKBOXES_RS485_LIST_SIZE_URL+`?role=${currentUser?.role}&id=${currentUser?.id}`,{
      method:"GET",
      headers: createHeader()
    })
      .then(
        res => res.json()
      ).then(
        res => {
          setBlackboxNumber(res)
        }
      )
    // waits until the request completes...

  }
  function isNumber(value) {
    return typeof value === 'number';
  }

  return (
    <a href='#' className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body'>
        <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x ms-n1`} />

        <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>Toplam Rs485 Komut Sayısı : 
        {isNumber(blackboxNumber) ? blackboxNumber : "bilinmiyor"}</div>

        <div className={`fw-semibold text-${descriptionColor}`}>Sistemde toplam kayıtlı komut sayısı</div>
      </div>
    </a>
  )
}

export {Rs458NumberWidget}
