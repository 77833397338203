/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'
import {
  BLACKBOXES_GET_LAST_5_ERROR, BLACKBOXES_GET_LIST_BY_COMPANYID,
  BLACKBOXES_GET_LIST_BY_DISTRIBUTORID, BLACKBOXES_DEVICE_LIST_URL
} from '../../../../urls'

type Props = {
  className: string
}

const DashboardLast5Error: React.FC<Props> = ({ className }) => {

  const { currentUser } = useAuth()
  const token = currentUser?.token
  const userRole = currentUser?.role
  const [blackboxList, setBlackboxList] = useState<any[]>([])

  const id = currentUser?.id
  const [currentBlackboxIndex, setCurrentBlackboxIndex] = useState(0)
  const [errorList, setErrorList] = useState([])
  const [showIncreaseButton, setShowIncreaseButton] = useState(true)
  const [showDecreaseButton, setShowDecreaseButton] = useState(true)
  const [firstInitialize, setFirstInitialize] = useState(true)
  const [deviceDescription, setDeviceDescription] = useState([])
  const createHeader = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', "" + token?.toString());
    return headers
  }


  async function getBlackboxList() {
    if (userRole === "3") {
      const response = await fetch(BLACKBOXES_GET_LIST_BY_COMPANYID + `?companyId=${currentUser?.id}`, {
        method: "GET",
        headers: createHeader(),

      })
        .then(
          res => res.json()
        ).then(
          res => {

            setBlackboxList(res)

          }
        )
      // waits until the request completes...
    } else if (userRole === "2") {
      const response = await fetch(BLACKBOXES_GET_LIST_BY_DISTRIBUTORID + `?distributorId=${currentUser?.id}`, {
        method: "GET",
        headers: createHeader(),

      })
        .then(
          res => res.json()
        ).then(
          res => {
            console.log(res)
            setBlackboxList(res)

          }
        )
      // waits until the request completes...
    }


  }
  async function getLast5Error() {
    console.log(blackboxList[currentBlackboxIndex])
    if (userRole != "1") {
      const response = await fetch(BLACKBOXES_GET_LAST_5_ERROR + `?blackboxId=${blackboxList[currentBlackboxIndex].serialNumber}`, {
        method: "GET",
        headers: createHeader(),

      })
        .then(
          res => res.json()
        ).then(
          res => {
            console.log(res)
            setErrorList(res)

          }
        )
      // waits until the request completes...


    }
  }
  async function getDevice() {
    
    if (userRole != "1") {
      const response = await fetch(BLACKBOXES_DEVICE_LIST_URL + `?blackboxId=${blackboxList[currentBlackboxIndex].serialNumber}`, {
        method: "GET",
        headers: createHeader(),

      })
        .then(
          res => res.json()
        ).then(
          res => {
            
            setDeviceDescription(res)
            
          }
        )
      // waits until the request completes...


    }
  }



  const increaseDeviceIndex = () => {
    if (blackboxList.length - 1 > currentBlackboxIndex) {
      setShowDecreaseButton(true)
      setCurrentBlackboxIndex(currentBlackboxIndex + 1)
    } else {
      setShowIncreaseButton(false)
    }
  }
  const decreaseDeviceIndex = () => {
    if (currentBlackboxIndex > 0) {
      setShowIncreaseButton(true)
      setCurrentBlackboxIndex(currentBlackboxIndex - 1)
    } else {
      setShowDecreaseButton(false)
    }

  }

  useEffect(() => {
    getDevice()
    if (firstInitialize) {
      getBlackboxList()
      setTimeout(() => getLast5Error(), 500)
      setFirstInitialize(false)
    } else {
      setTimeout(() => getLast5Error(), 300)
    }

  }, [currentBlackboxIndex, blackboxList]);



  return (
    <div>
      {(Array.isArray(blackboxList) && Array.isArray(errorList) && blackboxList.length != 0) &&
        <div className={`card ${className}`}>

          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Limit Aşımı Yapan Cihazlar</span>
            </h3>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            <div className='tab-content'>
              {/* begin::Tap pane */}
              <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='border-0'>
                        <th className='p-0 min-w-150px'></th>

                        <th className='p-0 min-w-110px'></th>

                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {
                        (Array.isArray(errorList) && errorList.length != 0) &&
                        errorList.map((currentBlackbox: any) => {


                          return (
                            <tr key={currentBlackbox["id"]}>

                              <td>
                                <a className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                  {deviceDescription.filter(device => device["uid"] === currentBlackbox["uid"]).map(device => device["description"])}
                                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {currentBlackbox["uid"]}
                                  </span>
                                </a>

                              </td>

                              <td>
                                <a className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                  {currentBlackbox != null ? currentBlackbox["sendTime"].replace("T", " ") : "-"}
                                </a>

                              </td>
                              <td className='text-end'>
                                <span>
                                  {currentBlackbox["message"]}
                                </span>
                              </td>

                            </tr>
                          )
                        })
                      }


                    </tbody>

                    {/* end::Table body */}
                  </table>

                </div>

                {/* end::Table */}
              </div>
              <div className='d-flex flex-stack flex-wrap pt-10'>
                <div className='fs-6 fw-bold text-gray-700'></div>

                <ul className='pagination'>
                  {showDecreaseButton &&
                    <li className='page-item previous' onClick={() => decreaseDeviceIndex()}>
                      <a className='page-link' >
                        <i className='previous'></i>
                      </a>
                    </li>
                  }
                  <li className='page-item active'>
                    <a className='page-link'>
                      {blackboxList[currentBlackboxIndex].blackboxName}
                    </a>
                  </li>

                  {showIncreaseButton &&
                    <li className='page-item next' onClick={() => increaseDeviceIndex()} >
                      <a className='page-link' >
                        <i className='next'></i>
                      </a>
                    </li>
                  }
                </ul>
              </div>
              {/* end::Tap pane */}
              {/* begin::Tap pane */}
              <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='border-0'>
                        <th className='p-0 w-50px'></th>
                        <th className='p-0 min-w-150px'></th>
                        <th className='p-0 min-w-140px'></th>
                        <th className='p-0 min-w-110px'></th>
                        <th className='p-0 min-w-50px'></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      <tr>
                        <td>
                          <div className='symbol symbol-45px me-2'>
                            <span className='symbol-label'>
                              <img
                                src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
                                className='h-50 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            Brad Simmons
                          </a>
                          <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                        </td>
                        <td className='text-end text-muted fw-semibold'>React, HTML</td>
                        <td className='text-end'>
                          <span className='badge badge-light-success'>Approved</span>
                        </td>
                        <td className='text-end'>
                          <a
                            href='#'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                          >
                            <KTIcon iconName='arrow-right' className='fs-2' />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='symbol symbol-45px me-2'>
                            <span className='symbol-label'>
                              <img
                                src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                                className='h-50 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            Popular Authors
                          </a>
                          <span className='text-muted fw-semibold d-block'>Most Successful</span>
                        </td>
                        <td className='text-end text-muted fw-semibold'>Python, MySQL</td>
                        <td className='text-end'>
                          <span className='badge badge-light-warning'>In Progress</span>
                        </td>
                        <td className='text-end'>
                          <a
                            href='#'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                          >
                            <KTIcon iconName='arrow-right' className='fs-2' />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='symbol symbol-45px me-2'>
                            <span className='symbol-label'>
                              <img
                                src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                                className='h-50 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            Active Customers
                          </a>
                          <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                        </td>
                        <td className='text-end text-muted fw-semibold'>AngularJS, C#</td>
                        <td className='text-end'>
                          <span className='badge badge-light-danger'>Rejected</span>
                        </td>
                        <td className='text-end'>
                          <a
                            href='#'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                          >
                            <KTIcon iconName='arrow-right' className='fs-2' />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </div>
                {/* end::Table */}
              </div>
              {/* end::Tap pane */}
              {/* begin::Tap pane */}
              <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='border-0'>
                        <th className='p-0 w-50px'></th>
                        <th className='p-0 min-w-150px'></th>
                        <th className='p-0 min-w-140px'></th>
                        <th className='p-0 min-w-110px'></th>
                        <th className='p-0 min-w-50px'></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      <tr>
                        <td>
                          <div className='symbol symbol-45px me-2'>
                            <span className='symbol-label'>
                              <img
                                src={toAbsoluteUrl('/media/svg/brand-logos/kickstarter.svg')}
                                className='h-50 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            Bestseller Theme
                          </a>
                          <span className='text-muted fw-semibold d-block'>Best Customers</span>
                        </td>
                        <td className='text-end text-muted fw-semibold'>ReactJS, Ruby</td>
                        <td className='text-end'>
                          <span className='badge badge-light-warning'>In Progress</span>
                        </td>
                        <td className='text-end'>
                          <a
                            href='#'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                          >
                            <KTIcon iconName='arrow-right' className='fs-2' />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='symbol symbol-45px me-2'>
                            <span className='symbol-label'>
                              <img
                                src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                                className='h-50 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            Active Customers
                          </a>
                          <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                        </td>
                        <td className='text-end text-muted fw-semibold'>AngularJS, C#</td>
                        <td className='text-end'>
                          <span className='badge badge-light-danger'>Rejected</span>
                        </td>
                        <td className='text-end'>
                          <a
                            href='#'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                          >
                            <KTIcon iconName='arrow-right' className='fs-2' />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='symbol symbol-45px me-2'>
                            <span className='symbol-label'>
                              <img
                                src={toAbsoluteUrl('/media/svg/brand-logos/vimeo.svg')}
                                className='h-50 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            New Users
                          </a>
                          <span className='text-muted fw-semibold d-block'>Awesome Users</span>
                        </td>
                        <td className='text-end text-muted fw-semibold'>Laravel,Metronic</td>
                        <td className='text-end'>
                          <span className='badge badge-light-primary'>Success</span>
                        </td>
                        <td className='text-end'>
                          <a
                            href='#'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                          >
                            <KTIcon iconName='arrow-right' className='fs-2' />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='symbol symbol-45px me-2'>
                            <span className='symbol-label'>
                              <img
                                src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                                className='h-50 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            Popular Authors
                          </a>
                          <span className='text-muted fw-semibold d-block'>Most Successful</span>
                        </td>
                        <td className='text-end text-muted fw-semibold'>Python, MySQL</td>
                        <td className='text-end'>
                          <span className='badge badge-light-warning'>In Progress</span>
                        </td>
                        <td className='text-end'>
                          <a
                            href='#'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                          >
                            <KTIcon iconName='arrow-right' className='fs-2' />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </div>
                {/* end::Table */}
              </div>
              {/* end::Tap pane */}
            </div>
          </div>
          {/* end::Body */}

        </div>
      }
    </div>
  )
}

export { DashboardLast5Error }
