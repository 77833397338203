/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import { useAuth } from '../../../../app/modules/auth'

type Props = {
  className: string
}

const DashbordCompanyInfoWidget: React.FC<Props> = ({className}) => {
  const { currentUser } = useAuth()
  
  
  function getPackage(packageLevel:string|undefined){
    if (packageLevel === "0") return "Bilinmiyor"
    else if(packageLevel === "1") return "Elfatek"
    else if(packageLevel === "2") return "Premium"
    else if(packageLevel === "4") return "Standart"
    else if(packageLevel === "5") return "Basic"

  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Genel Bakış</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Hesap Özeti</span>
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
          
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-success'>
              <KTIcon iconName='abstract-26' className='fs-2x text-success' />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
              {currentUser?.userName}
            </a>
            <span className='text-muted fw-semibold'>Email</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-warning'>
              <KTIcon iconName='pencil' className='fs-2x text-warning' />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
              {currentUser?.numberOfBlackboxPermission}
            </a>
            <span className='text-muted fw-semibold'>Karakutu Sayısı</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-primary'>
              <KTIcon iconName='message-text-2' className='fs-2x text-primary' />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                {getPackage(currentUser?.packageLevel)}
            </a>
            <span className='text-muted fw-semibold'>Paket</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-danger'>
              <KTIcon iconName='disconnect' className='fs-2x text-danger' />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
              {currentUser?.numberOfBlackbox}
            </a>
            <span className='text-muted fw-semibold'> Toplam Karakutu Sayısı</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-info'>
              <KTIcon iconName='security-user' className='fs-2x text-info' />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
              Bilinmiyor
            </a>
            <span className='text-muted fw-semibold'>Paket Süresi</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {DashbordCompanyInfoWidget}
