/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'
import { BLACKBOXES_BLACKBOX_GRAPHIC_GET_MONTLY_WEIGHT_URL,BLACKBOXES_GET_INDICATOR_GROUP_BY_DISTRIBUTORID,
   BLACKBOXES_GET_INDICATOR_GROUP_BY_COMPANYID } from '../../../../urls'
import { blackboxIdContext } from '../../../../app/modules/profile/components/contexts/BlackboxIdContext'
import { useAuth } from '../../../../app/modules/auth'

type Props = {
  className: string
}

const TotalWeightChart: React.FC<Props> = ({ className }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const { currentUser, logout } = useAuth()
  const currentBlackBoxId = useContext(blackboxIdContext);
  const token = currentUser?.token
  const [indicatorGroup, setIndicatorGroup] = useState([])
  const [pageInitializion, setPageInitializon] = useState(false)
  const [currentDeviceIndex, setCurrentDeviceIndex] = useState(0)
  const [chartData, setChartData] = useState([])
  const [loading, setLoading] = useState(true)

  const userRole = currentUser?.role
  let labels: any = [0, 1, 2]
  let labelsData: any = [0, 1, 2]

  const increaseDeviceIndex = () => {
    if (indicatorGroup.length - 1 > currentDeviceIndex)
      setCurrentDeviceIndex(currentDeviceIndex + 1)
  }
  const decreaseDeviceIndex = () => {
    if (currentDeviceIndex > 0)
      setCurrentDeviceIndex(currentDeviceIndex - 1)
  }
  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, chartData["label1"], chartData["date"]))
    if (chart) {
      chart.render()
    }

    return chart
  }
  const createHeader = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', "" + token?.toString());
    return headers
  }

  async function getIndicatorGroup() {
    if (userRole === "3") {
    try{

    
    const response = await fetch(BLACKBOXES_GET_INDICATOR_GROUP_BY_COMPANYID + `?companyId=${currentUser?.id}`, {
      method: "GET",
      headers: createHeader(),
    })
      .then(
        res => res.json()
      ).then(
        res => {
          if (res.status == 401) { logout() }
          
          setIndicatorGroup(res)
        })
      }finally{
        
      }
  }
  else if (userRole === "2") {
    try{

    
    const response = await fetch(BLACKBOXES_GET_INDICATOR_GROUP_BY_DISTRIBUTORID + `?distributorId=${currentUser?.id}`, {
      method: "GET",
      headers: createHeader(),
    })
      .then(
        res => res.json()
      ).then(
        res => {
          if (res.status == 401) { logout() }          
          setIndicatorGroup(res)
        })
      }finally{
        
      }
  }
}

  async function getMontlyWeightOneDevice() {
    try {
      const response = await fetch(BLACKBOXES_BLACKBOX_GRAPHIC_GET_MONTLY_WEIGHT_URL + `?uid=${indicatorGroup[currentDeviceIndex]["uid"]}`, {
        method: "GET",
        headers: createHeader(),
      })
        .then(
          res => res.json()
        ).then(
          res => {
            if (res.status == 401) { logout() }
            
            //setCurrentDeviceIndex(currentDeviceIndex + 1)
            setChartData(res)
            labels = (!Array.isArray(res) && (res["date"].length != 0 && Array.isArray(res["date"]))) ? res["date"].map(date => date.replace("T", " ")) : [0, 1, 2];
            labelsData = (!Array.isArray(res) && (res["label1"].length != 0 && Array.isArray(res["label1"]))) ? res["label1"] : [0, 1, 2];
            
          })
    }finally{
      setLoading(false)
    }
  }

  useEffect(() => {
    if (pageInitializion == false) {
      setTimeout(() => getMontlyWeightOneDevice(),300)
      setPageInitializon(true)
      getIndicatorGroup()
      
    }
    else{
     
    }
    //getMontlyWeightOneDevice()
   
    const chart = refreshMode()


    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode ,loading,indicatorGroup,chartData])

  if (loading) {
    return <p>Veri yükleniyor...</p>;
  }
  const myStyle = {
   
    padding: '10px',
    cursor: "pointer",
    transition: 'color 0.3s, background-color 0.3s', // Animasyon eklemek için
    
  };
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 d-flex justify-content-center'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Son 6 Ay Taşınan Yük</span>

          {/* <span className='text-muted fw-semibold fs-7'>More than 1000 new records</span> */}
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          <br></br>
          <ul className='pagination'>
            <li className='page-item previous'>
              <a className='page-link' onClick={() => decreaseDeviceIndex()} >
                <i className='previous'></i>
              </a>
            </li>

            <li className='page-item active'>
              <a className='page-link'
              onClick={() => getMontlyWeightOneDevice()}
              style={myStyle}>
                {indicatorGroup.length != 0 && indicatorGroup[currentDeviceIndex]["description"]}
              </a>
            </li>

            <li className='page-item next'>
              <a className='page-link' onClick={() => increaseDeviceIndex()} >
                <i className='next'></i>
              </a>
            </li>
          </ul>
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '350px' }}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { TotalWeightChart }

function getChartOptions(height: number, label1, label2): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-info-light')
  
  return {
    series: [
      {
        name: 'Toplam Yük',
        data: label1,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: label2,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' Kilogram'
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
