/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'



import { useAuth } from '../auth';
import { BLACKBOXES_BLACKBOX_GRAPHIC_GET_WEIGHT_OF_INDICATOR_DATETIME_URL, BLACKBOXES_BLACKBOX_GRAPHIC_GET_WEIGHT_OF_INDICATOR_URL, GET_INDICATOR_WEIGHT_FOR_PREDICTIVEMAINTANCE, SYSTEM_EVENT_LOGS_LIST_BY_BLACKBOXID, } from '../../../urls';
import { blackboxIdContext } from '../profile/components/contexts/BlackboxIdContext';
import { useLocation } from 'react-router-dom';
import { IndicatorWeightLabel } from './Indicator/IndicatorWeightLabel';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartDataset } from 'chart.js';
import { Button, ButtonGroup } from 'react-bootstrap';
import { EngageWidget1, FeedsWidget2, ListsWidget1, ListsWidget2, ListsWidget3, ListsWidget4, ListsWidget5, ListsWidget6, ListsWidget7, ListsWidget8, ListsWidget9, MixedWidget1, MixedWidget2, StatisticsWidget1, StatisticsWidget2, TablesWidget1, TablesWidget10, TablesWidget11, TablesWidget12, TablesWidget13, TablesWidget2, TablesWidget3, TablesWidget4, TablesWidget5, TablesWidget6, TablesWidget7, TablesWidget8, TilesWidget1, TilesWidget2, TilesWidget3, TilesWidget4, TilesWidget5 } from '../../../_elfaiotbbx/partials/widgets';
import { toAbsoluteUrl } from '../../../_elfaiotbbx/helpers';



export function LimitLog() {

    const { currentUser } = useAuth()
    const [id, setId] = useState(currentUser?.id)
    const currentBlackBoxId = useContext(blackboxIdContext);
    const location = useLocation();
    const uid = (location.state as { uid?: string })?.uid;
    const [limitLog, setLimitLog] = useState([])

    const [pageNumber, setPageNumber] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState()
    const [numberOfLimitLogs, setNumberOfLimitLogs] = useState([])
    const createHeader = () => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', "" + token?.toString());
        return headers
    }
    const token = currentUser?.token



    useEffect(() => {

        getLimitLogs( pageNumber, pageSize)
    }, [pageNumber, pageSize])
    async function getLimitLogs( page: number, size: number) {

        //const response = await fetch(BLACKBOXES_DEVICE_LIST_URL+`?blackboxId=${blackboxDetails["serialNumber"]}`, {
        const response = await fetch(SYSTEM_EVENT_LOGS_LIST_BY_BLACKBOXID + `?blackboxId=${currentBlackBoxId[0]}&size=${size}&page=${page}`, {
            method: "GET",
            headers: createHeader()
        })

            .then(
                res => res.json()
            )
            .then(
                res => {
                    console.log(res)
                    if(res.status != 404){
                        
                        setLimitLog(res["content"]);
                        //searchDistributorId(res["content"])
                        setPageNumber(res["pageable"]["pageNumber"])
                        setPageSize(res["pageable"]["pageSize"])
                        setTotalElements(res["totalElements"])
                        setTotalPages(res["totalPages"])
                        setNumberOfLimitLogs(res["content"].length)
                    }
                    

                }

            )

    }


    const pageReduce = () => {
   
        if(pageNumber >= 1 && pageNumber <= totalPages){
          
          setPageNumber(pageNumber-1)
          
          
        }
      }
      const pageIncrease = () => {
       
        if(pageNumber >= 0 && pageNumber < totalPages){
          setPageNumber(pageNumber+1)
          
        }
      }
      const pageChanger = () => {
    
        if(pageNumber >= 0){
    
        }
      }

    return (


        <>
            <div className='flex-grow-1 my-lg-0 my-2 me-2'>
                <a href='#' className='text-gray-800 fw-bold text-hover-primary fs-2'>
                    Sistem Logları
                </a>
                <span className='text-muted fw-semibold d-block pt-1'>Kullanıcıların sistem üzerindeki değişiklerini gösteren panel</span>

            </div>
            
            
            <div>
                {/* begin::Header */}
                
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted bg-light'>
                                    <th className='ps-4 min-w-300px rounded-start'>Cihaz Id</th>
                                    <th className='min-w-125px'></th>
                                    <th className='min-w-125px'>Durum</th>
                                    
                                    <th className='min-w-200px'></th>
                                    <th className='min-w-150px'>Hata Tarihi</th>
                                    <th className='min-w-200px text-end rounded-end'></th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {
                                    (limitLog.length != 0) &&
                                    limitLog.map((error: any) => {

                                        return (
                                            <tr>
                                                <td key={error.nodeId}>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='symbol symbol-50px me-5'>
                                                            <span className='symbol-label bg-light'>
                                                                <img
                                                                    src={toAbsoluteUrl('/media/svg/avatars/001-boy.svg')}
                                                                    className='h-75 align-self-end'
                                                                    alt=''
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className='d-flex justify-content-start flex-column'>
                                                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                                {error["uid"]}
                                                            </a>
                                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                                {error["can"]}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {/* {error["text"]} */}
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {error["text"]}
                                                    </a>
                                                    <span className='text-muted fw-semibold text-muted d-block fs-7'></span>
                                                </td>
                                                <td>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {/* {error.description} */}
                                                    </a>
                                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>

                                                    </span>
                                                </td>
                                                <td>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {error.eventDate.replace("T", " ")}
                                                        {/* {blackbox.createdTime.replace("T"," ")} */}
                                                    </a>
                                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>

                                                    </span>
                                                </td>

                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
                <div className='d-flex flex-stack flex-wrap pt-10'>
                    <div className='fs-6 fw-bold text-gray-700'>Showing {(pageNumber) * 10} to {(pageNumber + 1) * 10} of {totalElements} entries</div>

                    <ul className='pagination'>
                        <li className='page-item previous'>
                            <a className='page-link' onClick={pageReduce}>
                                <i className='previous'></i>
                            </a>
                        </li>

                        <li className='page-item active'>
                            <a href='#' className='page-link'>
                                {pageNumber + 1}
                            </a>
                        </li>

            



                        <li className='page-item next'>
                            <a className='page-link' onClick={pageIncrease}>
                                <i className='next'></i>
                            </a>
                        </li>
                    </ul>
                </div>

            </div>



        </>

    )
}
