import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_elfaiotbbx/layout/core'

import { DashbordCompanyInfoWidget } from '../../../_elfaiotbbx/partials/widgets/lists/DashbordCompanyInfoWidget'

import { KTIcon } from '../../../_elfaiotbbx/helpers'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { LIST_PREDICTIVEMAINTENANCE } from '../../../urls'
import { useContext, useEffect, useState } from 'react'
import { useAuth } from '../auth'
import { blackboxIdContext } from '../profile/components/contexts/BlackboxIdContext'
import { DashboardLast5Error } from '../../../_elfaiotbbx/partials/widgets/tables/DashboardLast5Error'
import { TablesWidget9 } from '../../../_elfaiotbbx/partials/widgets'
import { PercentTable } from './PercentTable'

const ReparationMainWindowList = () => {
  const location = useLocation();
  const uid = (location.state as { uid?: string })?.uid;
  const deviceDescription = (location.state as { deviceDescription?: string })?.deviceDescription;
  const { currentUser, logout } = useAuth()
  const currentBlackBoxId = useContext(blackboxIdContext);
  const token = currentUser?.token
  const [tableOfReparation, setTableOfReparation] = useState([])
  const [pageInitialize, setPageInitialize] = useState(false)
  const [startNumber, setStartNumber] = useState(0)
  const [endNumber, setEndNumber] = useState(10)
  const createHeader = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', "" + token?.toString());
    return headers
  }

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>

          <Link
            state={{ uid: uid }}
            to={`/crafted/reparation/addreparation`}
            className={`card bg-body-white hoverable card-xl-stretch mb-xl-8`}>
            <div className='card-body'>
              <KTIcon iconName='plus' className={`text-primary fs-3x ms-n1`} />

              <div className={`text-gray-900 fw-bold fs-2 mb-2 mt-5`}>Kart ekle
              </div>

              <div className={`fw-semibold text-gray-400`}>Elektronik ve ya mekanik sistem kontrolü</div>
            </div>
          </Link>
        </div>

      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-4'>

        </div>


      </div>




      <div className='row gy-5 g-xl-8'>
        <PercentTable className='card-xl-stretch mb-xl-8' uid={uid} deviceDescription={deviceDescription}></PercentTable>
      </div>




    </>
  )
}

const ReparationMainWindowListWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <ReparationMainWindowList />
    </>
  )
}

export { ReparationMainWindowList }
