/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'



import { useAuth } from '../auth';
import { BLACKBOXES_BLACKBOX_GRAPHIC_GET_WEIGHT_OF_INDICATOR_DATETIME_URL, BLACKBOXES_BLACKBOX_GRAPHIC_GET_WEIGHT_OF_INDICATOR_URL, GET_INDICATOR_WEIGHT_FOR_PREDICTIVEMAINTANCE, } from '../../../urls';
import { blackboxIdContext } from '../profile/components/contexts/BlackboxIdContext';
import { useLocation } from 'react-router-dom';
import { IndicatorWeightLabel } from './Indicator/IndicatorWeightLabel';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartDataset } from 'chart.js';
import { Button, ButtonGroup } from 'react-bootstrap';



export function IndicatorMainWindow() {

  const { currentUser } = useAuth()
  const [id, setId] = useState(currentUser?.id)
  const currentBlackBoxId = useContext(blackboxIdContext);
  const location = useLocation();
  const uid = (location.state as { uid?: string })?.uid;
  const [indicatorWeight, setIndicatorWeight] = useState([])
  const [isIndicator, setIsIndicator] = useState(true)
  const [deviceType, setDeviceType] = useState("2")
  const [interval, setInterval] = useState("1")
  const [graphicData, setGraphicData] = useState<any>([])
  const [resetChart, setResetChart] = useState(false)
  const handleInterval = (parametre:string) =>{setInterval(parametre)}
  const createHeader = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', "" + token?.toString());
    return headers
  }
  const token = currentUser?.token



  useEffect(() => {
    getIndicatorWeight()
    getweightofindicator()

  }, [interval])
  async function getIndicatorWeight() {

    //const response = await fetch(BLACKBOXES_DEVICE_LIST_URL+`?blackboxId=${blackboxDetails["serialNumber"]}`, {
    const response = await fetch(GET_INDICATOR_WEIGHT_FOR_PREDICTIVEMAINTANCE + `?uid=${uid}`, {
      method: "GET",
      headers: createHeader()
    })

      .then(
        res => res.json()
      )
      .then(
        res => {

          
          setIndicatorWeight(res)
          if (res["sumLoad2"] != null) {
            setIsIndicator(false)
            setDeviceType("1")
          }
        }

      )

  }
  async function getweightofindicator() {
    
    if (interval === "1") {
      const response = await fetch(BLACKBOXES_BLACKBOX_GRAPHIC_GET_WEIGHT_OF_INDICATOR_DATETIME_URL + `?uid=${uid}&deviceType=${deviceType}&interval=${interval}`, {
        method: "GET",
        headers: createHeader()
      })

        .then(
          res => res.json()
        )
        .then(
          res => {

           
            setGraphicData(res)
            setResetChart(false)
          }

        )
    }
    else {
      //const response = await fetch(BLACKBOXES_DEVICE_LIST_URL+`?blackboxId=${blackboxDetails["serialNumber"]}`, {
      const response = await fetch(BLACKBOXES_BLACKBOX_GRAPHIC_GET_WEIGHT_OF_INDICATOR_URL + `?uid=${uid}&deviceType=${deviceType}&interval=${interval}`, {
        method: "GET",
        headers: createHeader()
      })

        .then(
          res => res.json()
        )
        .then(
          res => {

            
            setGraphicData(res)
            setResetChart(false)
          }

        )
    }
  }
  let labels: any = [0, 1, 2]
  let labelsData: any = [0, 1, 2]
  let labels2Data: any = [0, 1, 2]
  let negatedData: any = [0, 1, 2]
  if (!Array.isArray(graphicData)) {
    labels = (resetChart == false && !Array.isArray(graphicData) && (graphicData["date"].length != 0 && Array.isArray(graphicData["date"]))) ? graphicData["date"] : [0, 1, 2];
    labelsData = (resetChart == false && !Array.isArray(graphicData) && (graphicData["label1"].length != 0 && Array.isArray(graphicData["label1"]))) ? graphicData["label1"] : [0, 1, 2];
    labels2Data = (resetChart == false && !Array.isArray(graphicData) && (graphicData["label2"].length != 0 && Array.isArray(graphicData["label2"]))) ? graphicData["label2"] : [0, 1, 2]


  }
  let data = {
    labels,
    datasets: [
      // {
      //   label: "Tarih",
      //   data: labels.map(() =>[0, labelsData.splice(0, 1)]),
      //   backgroundColor: 'rgba(255, 99, 132, 0.5)',
      //   borderColor: 'rgb(53, 162, 235)',
      // },
      {
        label: "Yük-1",
        data: labelsData.length != 0 ? labels.map(() => [0, labelsData.splice(0, 1)]) : [],
        borderColor: 'rgb(53, 162, 235)',
        fill: false,
      },
      {
        label: "Yük-2",
        data: labels2Data.length != 0 ? labels.map(() => [0, labels2Data.splice(0, 1)]) : [],
        borderColor: "rgba(255, 99, 132, 1)",
        fill: false,
      },

    ],
  };

  return (


    <>
      {(indicatorWeight.length != 0) &&
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4'>
            <IndicatorWeightLabel
              className='card-xl-stretch mb-xl-8'
              svgIcon='basket'
              color='body-white'
              iconColor='primary'
              title='Shopping Cart'
              description='Lands, Houses, Ranchos, Farms'
              titleColor='gray-900'
              descriptionColor='gray-400'
              showData={indicatorWeight["sumLoad1"]}
              explanation="Toplam Taşınan Yaklaşık Yük (kg)"
              explanation1={indicatorWeight["lastDateForLoad1"]}
            />
          </div>
          <div className='col-xl-4'>
            <IndicatorWeightLabel
              className='card-xl-stretch mb-xl-8'
              svgIcon='basket'
              color='body-white'
              iconColor='primary'
              title='Shopping Cart'
              description='Lands, Houses, Ranchos, Farms'
              titleColor='gray-900'
              descriptionColor='gray-400'
              showData={indicatorWeight["maxWeightLoad1"]}
              explanation="En Yüksek Taşınan Yük (kg)"
              explanation1={indicatorWeight["maxWeighDatetForLoad1"]}
            />
          </div>
          <div className='col-xl-4'>
            <IndicatorWeightLabel
              className='card-xl-stretch mb-xl-8'
              svgIcon='basket'
              color='body-white'
              iconColor='primary'
              title='Shopping Cart'
              description='Lands, Houses, Ranchos, Farms'
              titleColor='gray-900'
              descriptionColor='gray-400'
              showData={indicatorWeight["lastWeightLoad1"]}
              explanation="Son Taşınan Yük (kg)"
              explanation1={indicatorWeight["lastDateForLoad1"]}
            />
          </div>
          {
            isIndicator == false &&
            <>
              <div className='col-xl-4'>
                <IndicatorWeightLabel
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='basket'
                  color='body-white'
                  iconColor='primary'
                  title='Shopping Cart'
                  description='Lands, Houses, Ranchos, Farms'
                  titleColor='gray-900'
                  descriptionColor='gray-400'
                  showData={indicatorWeight["sumLoad2"]}
                  explanation="Toplam Taşınan Tahmini Yük"
                  explanation1={indicatorWeight["lastDateForLoad2"]}
                />
              </div>

            </>

          }

          <div className="container">
            <div className="row">
              <div className="col offset-md-2">
                <h2>Line Example</h2>
                <div className="chart-container pl-3" style={{ height: '95%', width: '80%' }}>
                  <Line data={data} />
                  <div className="row justify-content-center">
                    <ButtonGroup aria-label="Basic example">
                      <Button 
                      variant="secondary"
                      onClick={() => handleInterval("1")}>Günlük</Button>
                      <Button 
                      variant="secondary"
                      onClick={() => handleInterval("2")}>Aylık</Button>
                      <Button 
                      variant="secondary"
                      onClick={() => handleInterval("3")}>Yıllk</Button>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      }
    </>

  )
}
