import { Suspense, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_elfaiotbbx/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_elfaiotbbx/layout/core'
import { MasterInit } from '../_elfaiotbbx/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { ThemeModeProvider } from '../_elfaiotbbx/partials'
import { blackboxIdContext } from './modules/profile/components/contexts/BlackboxIdContext'

const App = () => {
  const [currentBlackboxId, setCurrentBlackboxId] = useState("none")
  // console.error = () => {};
  // console.warn = () => {};
  // console.log = () => {};
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <blackboxIdContext.Provider 
            value={[currentBlackboxId, setCurrentBlackboxId]}>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </blackboxIdContext.Provider>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
