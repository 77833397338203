/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTIcon} from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'
import { BLACKBOXES_BLACKBOX_TABLE_GRAPHIC_GET_TOTAL_CAR_TIME, BLACKBOXES_BLACKBOX_TABLE_GRAPHIC_GET_URL, BLACKBOXES_BLACKBOX_TABLE_GRAPHIC_URL, BLACKBOXES_GET_CANIOLABEL, BLACKBOXES_GET_LIST_SIZE_URL } from '../../../../urls'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
  deviceList:any
}

const CarSumMovement: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  deviceList
}) => {
  
  const {currentUser, logout} = useAuth()
  const token = currentUser?.token
  const [toalCarTime, setTotalCarTime] = useState<any>(0)
  const carTimeList:number[] = []
  const createHeader = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', "" + token?.toString());
    return headers
}

  useEffect(() => {
    getCarTime()
  },[deviceList]) 

  async function getCarTime() {
        
    const response = await fetch(BLACKBOXES_BLACKBOX_TABLE_GRAPHIC_GET_TOTAL_CAR_TIME + `?role=${currentUser?.role}&id=${currentUser?.id}`, {
        method: "GET",
        headers: createHeader(),
    })
    const data = await response.json()
    console.log(119859342385734290587234985230974592834578)
    console.log(data)
    setTotalCarTime(data)
   }
    


  function isNumber(value) {
    return typeof value === 'number';
  }
  function totalFormatTime(craneFirstAxis) {
    const date = new Date(craneFirstAxis * 1000); // Saniyeleri milisaniyelere çevirme
    
    const totalDate = date.getTime() 
    const resultDate = new Date(totalDate);

    const hours = resultDate.getUTCHours();
    const minutes = resultDate.getUTCMinutes();
    const remainingSeconds = resultDate.getUTCSeconds();

    
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    return formattedTime;
}
  return (
    <a href='#' className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body'>
        <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x ms-n1`} />

        <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>Toplam Araba hareket süresi : 
         {isNumber(toalCarTime) ? totalFormatTime(toalCarTime)  : "bilinmiyor"} 
        </div>

        <div className={`fw-semibold text-${descriptionColor}`}>Tüm vinç araba hareket sürelerinin toplamı</div>
      </div>
    </a>
  )
}

export {CarSumMovement}
