import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_elfaiotbbx/layout/core'
import { BlackboxNumberWidget } from '../../../_elfaiotbbx/partials/widgets/statistics/BlackboxNumberWidget'
import { BlackboxDeviceNumberWidget } from '../../../_elfaiotbbx/partials/widgets/statistics/DeviceNumberWidget'
import { Rs458NumberWidget } from '../../../_elfaiotbbx/partials/widgets/statistics/Rs458NumberWidget'
import { DashbordBlackboxTable } from '../../../_elfaiotbbx/partials/widgets/tables/DashbordBlackboxTable'
import { DashbordCompanyInfoWidget } from '../../../_elfaiotbbx/partials/widgets/lists/DashbordCompanyInfoWidget'
import { TotalWeightChart } from '../../../_elfaiotbbx/partials/widgets/charts/TotalWeightChart'
import { DashboardLast5Error } from '../../../_elfaiotbbx/partials/widgets/tables/DashboardLast5Error'
import { SumLoad } from '../../../_elfaiotbbx/partials/widgets/statistics/SumLoad'
import { CarSumMovement } from '../../../_elfaiotbbx/partials/widgets/statistics/CarSumMovement'
import { BridgeSumMovement } from '../../../_elfaiotbbx/partials/widgets/statistics/BridgeSumMovement'
import { BLACKBOXES_DEVICE_LIST_URL, BLACKBOXES_GET_DEVICELIST_WITH_PAGEABLE } from '../../../urls'
import { useContext, useEffect, useState } from 'react'
import { blackboxIdContext } from '../../modules/profile/components/contexts/BlackboxIdContext'
import { useAuth } from '../../modules/auth'

const MainWindow = () => (
  <>
    {/* begin::Row */}
    

    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      {/* begin::Col */}
      <div className='col-xl-4'>
        <DashbordCompanyInfoWidget className='card-xl-stretch mb-xl-8' />
      </div>
      {/* end::Col */}
      {/* begin::Col */}
      <div className='col-xl-8'>
        <DashbordBlackboxTable className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
      {/* end::Col */}
    </div>
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-6'>
        <TotalWeightChart className='card-xl-stretch mb-xl-8'></TotalWeightChart>
      </div>
      <div className='col-xl-6'>
        <DashboardLast5Error className='card-xl-stretch mb-xl-8'></DashboardLast5Error>
      </div>
    </div>
    {/* end::Row */}

  </>
)

const MainWindowTopHeader = () => (
  <>
    {/* begin::Row */}
    


    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <BlackboxNumberWidget
          className='card-xl-stretch mb-xl-8'
          svgIcon='element-11'
          color='primary'
          iconColor='gray-100'
          title='Shopping Cart'
          description='Lands, Houses, Ranchos, Farms'
          titleColor='gray-900'
          descriptionColor='gray-400'
        />
      </div>

      <div className='col-xl-4'>
        <BlackboxDeviceNumberWidget
          className='card-xl-stretch mb-xl-8'
          svgIcon='element-11'
          color='primary'
          iconColor='white'
          title='Appartments'
          description='Flats, Shared Rooms, Duplex'
          titleColor='white'
          descriptionColor='gray-400'
        />
      </div>

      <div className='col-xl-4'>
        <Rs458NumberWidget
          className='card-xl-stretch mb-5 mb-xl-8'
          svgIcon='element-11'
          color='primary'
          iconColor='gray-100'
          title='Sales Stats'
          description='50% Increased for FY20'
          titleColor='white'
          descriptionColor='gray-400'
        />
      </div>
    </div>
    {/* end::Row */}


  </>
)

const MainWindowSumFeatures = (data:any) => (
  <>
    {/* begin::Row */}
    


    


    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <SumLoad
          className='card-xl-stretch mb-xl-8'
          svgIcon='left'
          color='primary'
          iconColor='gray-100'
          title='Shopping Cart'
          description='Lands, Houses, Ranchos, Farms'
          titleColor='gray-900'
          descriptionColor='gray-400'
        />
      </div>

      <div className='col-xl-4'>
        <CarSumMovement
          className='card-xl-stretch mb-xl-8'
          svgIcon='left'
          color='primary'
          iconColor='white'
          title='Appartments'
          description='Flats, Shared Rooms, Duplex'
          titleColor='white'
          descriptionColor='gray-400'
          deviceList={data}
        />
      </div>

      <div className='col-xl-4'>
        <BridgeSumMovement
          className='card-xl-stretch mb-5 mb-xl-8'
          svgIcon='left'
          color='primary'
          iconColor='gray-100'
          title='Sales Stats'
          description='50% Increased for FY20'
          titleColor='white'
          descriptionColor='gray-400'
        />
      </div>
    </div>


  </>
)


const DashboardWrapper = () => {
  const intl = useIntl()
  
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <MainWindow />
    </>
  )
}

export {MainWindow, MainWindowTopHeader, MainWindowSumFeatures}
